import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// React boostrap
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
//SEO Matter
import Seo from "../components/seo"
const IndexPage = () => (
  <div id="Index">
    <Seo
      title="Abris vélos | Supports vélos | Range vélos - BIKESAFE"
      description="Spécialisée et reconnue dans la fabrication de stationnement et de rangement pour vélos, Bike Safe conçoit, personnalise et fournit une gamme diversifiée de produits adaptée selon vos besoins."
    />
    <Layout>
      <Container fluid className="p-0">
        <Row className="mb-5">
          <Col className="text-center no-padding-or-margin">
            <div className="parallax-home"><div className="home-title"><h1 className="home-title-text">
              Le spécialiste</h1>
              <h1 className="home-title-text-under opacity">
                du stationnement vélo</h1></div></div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Range Vélos</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src='./product/61/homepage-61.jpg'></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos - 2 niveaux</h3>
              <p>Le range vélos à deux niveaux est un système très innovant et pratique qui permet d'accueillir deux fois plus de vélos que tous les autres range vélos standards.</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="./product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe - Range Vélos - 2 niveaux</h3>
              <p>Le porte-vélos à deux niveaux BikeSafe est un système de stationnement pour vélos pionnier et convivial, avec un excellent stockage de vélos peu encombrant...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="./product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos autonome, 1 ou 2 côtés</h3>
              <p>Le range vélos autonome permet aux utilisateurs d'optimiser leur espace au sol. L'armature peut être boulonnée dans le sol à n'importe...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="./product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos semi-vertical</h3>
              <p>Pour optimiser l'utilisation d'un espace réduit. Les supports semi-verticaux peuvent être utilisés à l'intérieur comme à l'extérieur. La roue arrière peut être...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Supports Pour Vélos</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Support vélos traditionnel</h3>
              <p>Ce design classique et très demandé représente une solution de stationnement pour vélos simple qui offre un bon rapport qualité-prix et nécessite une faible maintenance. </p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Support à arceau traditionnel noir</h3>
              <p> Sa forme permet d'accrocher le vélo au support au niveau du cadre et de la roue. Ce design classique et très demandé représente une solution de stationnement...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Abris Vélos</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/233">
              <StaticImage placeholder="blurred" className="fluid" src="./product/233/homepage-233.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Abri Vélos New York Deux Niveaux</h3>
              <p>Cet superbe abri vélos est livré avec un toit anti-escalade et est parfait pour les installations dans les espaces publics.</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/38">
              <StaticImage placeholder="blurred" className="fluid" src="./product/38/homepage-38.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Abri en Arc Deux Niveaux</h3>
              <p>Conçu pour doubler la capacité de rangement des vélos dans un espace relativement réduit avec ses deux rangées de stationnement. Abri disponible avec une finition galvanisée ou revêtement...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="4" className="p-2">
            <Link to="/product/22">
              <StaticImage placeholder="blurred" className="fluid" src="./product/22/homepage-22.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Abri Dijon Deux Niveaux</h3>
              <p>Cet abri est doté des meilleures caractéristiques et de formes arrondies qui s'adaptent à tout type d'environnement. Un abri en acier doux avec fixation par boulonnage.</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Le spécialiste du stationnement vélo</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="p-2">
            <p>Spécialisée et reconnue dans la fabrication de stationnement et de rangement pour vélos, Bike Safe conçoit, personnalise et fournit une gamme diversifiée de produits adaptée selon vos besoins.</p>
            <p>Bike Safe est le fournisseur officiel concernant les supports vélos, les racks à vélos et les abris pour vélos des quatre itinéraires existants du Programme Barclays Cycle Superhighway (programme de pistes cyclables de Barclays) en partenariat avec Transport for London (organisme public chargé des transports en commun à Londres) .</p>
            <p>En effet, les « Barclays Cycle Superhighways » sont de nouvelles pistes cyclables qui relient le centre de Londres et sa banlieue afin de fournir aux cyclistes des itinéraires plus sûrs, plus rapides et plus directs.</p>
            <p>Grâce à Bike Safe, ce programme fournit des milliers de nouvelles places de stationnement pour vélos, une formation sur le cyclisme urbain gratuite ou subventionnée ainsi que de meilleurs aménagements sur les lieux de travail des cyclistes.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
)

export default IndexPage
